body {
  background-color: #000;
}
.App {
  text-align: center;
}
.App-Browser {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.Modal-Block-Interaction-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
