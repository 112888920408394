.Post-Image-Preview-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: var(--modal-background-film);
  backdrop-filter: blur(6px);
  z-index: 2;
}
.Post-Image-Preview-Modal-Left {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 300px;
  padding: 10px 10px 50px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Post-Image-Preview-Modal-Right {
  width: 300px;
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  padding: 5px;
  box-shadow: var(--shadow-inset);
  background: var(--background-primary);
  overflow: scroll;
  top: 0;
  bottom: 0;
  right: 0;
}
.Post-Image-Preview-Modal-Img {
  max-width: 100%;
  max-height: 100%;
}
.Post-Image-Preview-Modal-Option {
  position: absolute;
  color: white;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: var(--shadow-inset);
  background-color: rgba(24, 24, 27, 0.75);
  height: 2.5rem !important;
  width: 2.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
}
.Post-Image-Preview-Modal-Option > svg {
  font-size: 2.5rem;
}
.Post-Image-Preview-Modal-Arrow-Back {
  left: 20px;
}
.Post-Image-Preview-Modal-Arrow-Forward {
  right: 20px;
}
.Post-Image-Preview-Modal-Close {
  right: 20px;
  top: 20px;
}
.Post-Image-Bottom-Options {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
}
.Post-Image-Preview-Focused-Item-Post-Container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 0px 12px;
  padding-top: 12px;
  min-height: 100px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
}
.Post-Image-Preview-Focused-Item-Post-Container-Top {
  display: flex;
}
.Post-Image-Preview-Focused-Item-Info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.Post-Image-Preview-Focused-Item-Header {
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Post-Image-Preview-Focused-Item-Reply-Button {
  display: block;
  margin-left: auto;
  padding: 9px 30px;
  margin: 10px;
  font-size: 1.6rem;
  background: var(--background-secondary);
  color: var(--primary-accent);
  font-weight: 600;
  cursor: pointer;
  border: 1px solid black;
  box-sizing: border-box;
  transition: all 0.2s;
}
.Post-Image-Preview-Focused-Item-Reply-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
}
