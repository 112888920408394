.Set-Status-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  z-index: 2;
}

.Set-Status-Container {
  position: absolute;
  min-height: 330px;
  width: 600px;
  max-height: calc(100vh - 60px);
  background-color: var(--background-secondary);
  box-shadow: var(--shadow-inset);
  pointer-events: auto;
  top: 10px;
  right: calc(50% - 300px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .Set-Status-Container {
    top: 0;
    width: unset;
    max-height: unset;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.Status-Text-Modal-Input-Wrapper {
  width: 95%;
  margin: auto;
}
.Status-Text-Modal-Input {
  width: 100% !important;
  background: var(--background-tertiary) !important;
}
.Set-Status-Container-Inner {
  padding: 10px;
}
.Set-Status-Container-Inner > h2 {
  color: var(--font-color);
}
.Set-Status-Button-Container {
  padding: 10px;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}
.Set-Status-Button-Container > div {
  margin-right: 10px;
}
.Set-Status-Buttons {
  margin-left: auto;
}

.Set-Status-Button-1 {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 6px 40px;
  color: var(--font-color);
}
@media (hover: hover) {
  .Set-Status-Button-1:hover {
    text-decoration: underline;
  }
}
.Set-Status-Button-2 {
  background: var(--primary-accent);
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 6px 40px;
  border: none;
}
@media (hover: hover) {
  .Set-Status-Button-2:hover {
    filter: brightness(80%);
  }
}

.radio-with-Icon {
  display: flex;
  margin-bottom: 30px;
}
.radio-with-Icon .radioOption-Item {
  display: inline-block;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  margin: 0px 15px;
  border: none;
}
.radio-with-Icon .radioOption-Item label {
  display: block;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  color: var(--font-color-alt);
  cursor: pointer;
  opacity: 0.5;
  transition: none;
  font-size: 1.3rem;
  text-align: center;
  margin: 0 !important;
}
.radio-with-Icon .radioOption-Item label:hover,
.radio-with-Icon .radioOption-Item label:focus,
.radio-with-Icon .radioOption-Item label:active {
  opacity: 0.8;

  color: var(--font-color);
  margin: 0 !important;
}
.radio-with-Icon .radioOption-Item label::after,
.radio-with-Icon .radioOption-Item label:after,
.radio-with-Icon .radioOption-Item label::before,
.radio-with-Icon .radioOption-Item label:before {
  display: none;
}
.radio-with-Icon .radioOption-Item label i.fa {
  display: block;
  font-size: 50px;
}
.radio-with-Icon .radioOption-Item input[type='radio'] {
  display: none;
}
.radio-with-Icon .radioOption-Item input[type='radio']:active ~ label {
  opacity: 1;
}
.radio-with-Icon .radioOption-Item input[type='radio']:checked ~ label {
  opacity: 1;
  color: var(--font-color);
}
.radio-with-Icon .radioOption-Item input[type='radio']:hover,
.radio-with-Icon .radioOption-Item input[type='radio']:focus,
.radio-with-Icon .radioOption-Item input[type='radio']:active {
  margin: 0 !important;
}
.radio-with-Icon .radioOption-Item input[type='radio'] + label:before,
.radio-with-Icon .radioOption-Item input[type='radio'] + label:after {
  margin: 0 !important;
}
