.Navbar {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background: var(--background-primary);
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}
.Navbar-Inner-Wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.Navbar-Inner-L {
  height: 100%;
  display: flex;
}
.Navbar-Inner-L > .Nav-Option > svg {
  display: none;
}
.Navbar-Inner-R {
  height: 100%;
  display: flex;
}
.Navbar-Inner-C {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.Navbar-Searchbar {
  flex-basis: 550px;
  width: 100%;
  max-width: 550px;
  margin: 6px;
  position: relative;
  background-color: var(--background-secondary);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: var(--shadow-inset);
}

.Navbar-Avatar {
  height: 48px !important;
  width: 48px !important;
  margin-bottom: 0px !important;
  margin-left: 10px;
  align-self: flex-start;
  border: none !important;
  border-left: 2px solid lime !important;
}
.Navbar-Avatar-Live {
  border-left: 2px solid var(--status-live) !important;
}
.Navbar-Avatar-Live-Youtube {
  border-left: 2px solid var(--youtube-red) !important;
}
.Navbar-Avatar-Live-Twitch {
  border-left: 2px solid var(--twitch-purple) !important;
}

.Nav-Option > svg {
  font-size: 1.9rem;
}
.Nav-Option {
  color: var(--font-color-alt);
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid transparent;
  text-shadow: var(--text-shadow);
  background: none;
}
.Nav-Option > span {
  display: block;
  height: 21px;
  text-transform: uppercase;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (hover: hover) {
  .Nav-Option:hover {
    background: var(--background-secondary);
    color: var(--font-color);
    border-bottom: 1px solid var(--primary-accent);
  }
}
.Nav-Option-Active {
  background: var(--background-secondary);
  color: var(--font-color);
  border-bottom: 1px solid var(--primary-accent);
}

.Nav-Option-Avatar {
  padding-right: 0 !important;
  max-width: 300px;
  box-sizing: border-box;
  color: var(--font-color);
}

.Header-Name-Title {
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  margin: 0px 20px;
  transition: all 500ms;
  cursor: pointer;
}
.Header-Name-Title > span {
  vertical-align: -20%;
  margin-top: -10px;
}
.Header-Name-Title > span {
  display: block;
  text-decoration: none;
  font-family: 'Fujimaru';
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: var(--font-color);
  background: -webkit-linear-gradient(grey, var(--primary-accent));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 400ms;
}
@media (hover: hover) {
  .Header-Name-Title:hover > span {
    background: -webkit-linear-gradient(var(--primary-accent), white);
    background-clip: text;
  }
}
.Header-Name-Title > .Title-Alt {
  display: none;
}
.Nav-Displayname-Emojis {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -20%;
}
.Nav-Displayname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: var(--text-shadow);
}
.Nav-Displayname > div {
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
}
.Notfications-Count {
  margin-left: 5px;
}
.Notifications-Active {
  color: var(--primary-accent);
  border-bottom: 1px solid var(--primary-accent);
}

@media screen and (max-width: 900px) {
  .Navbar-Inner-L > .Nav-Option > svg {
    display: unset;
  }
  .Navbar-Inner-L > .Nav-Option > span {
    display: none;
  }
  .Header-Name-Title {
    margin: 0 10px;
  }
  .Header-Name-Title > span {
    display: none;
  }
  .Header-Name-Title > .Title-Alt {
    display: unset;
  }
  .Nav-Option-Avatar {
    padding-left: 0px;
    display: flex;
  }
  .Navbar-Avatar {
    margin: 0;
  }
  .Nav-Option-Avatar > svg {
    display: none;
  }
  .Nav-Displayname {
    display: none !important;
  }
}
@media screen and (max-width: 650px) {
  .Nav-Option {
    display: none;
  }
  .Nav-Option-Mobile {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
  .Nav-Option-Avatar {
    padding-left: 0px;
    display: flex;
  }
}
