.Theme-Editor-Container {
  color: var(--font-color);
  margin-top: 30px;
  text-align: left;
}
@media screen and (max-width: 950px) {
  .Theme-Editor-Container {
    margin: 0;
  }
  .Theme-Editor-Container > h1 {
    display: none;
  }
}
.Theme-Editor-Container > h2 {
  color: var(--font-color-alt);
  margin-bottom: 2px;
}
.Theme-Editor-Subheader {
  color: var(--font-color-alt);
  margin-bottom: 2px;
  font-size: 1.3rem;
  font-weight: 600;
  max-width: 600px;
}
.Emoji-Editor-Accent-Selection-List {
  display: flex;
  margin-top: 5px;
}
.Emoji-Editor-Accent-Selection-Item {
  height: 50px;
  width: 50px;
  border: 1px solid transparent;
  box-shadow: var(--shadow-inset);
  margin: 10px;
  cursor: pointer;
  transition: all 500ms;
}
.Emoji-Editor-Accent-Seiso {
  background-color: #66fcf1;
}
.Emoji-Editor-Accent-Seiso:hover {
  box-shadow: 0px 0px 5px #66fcf1, 0px 0px 10px #66fcf1, 0px 0px 20px #66fcf1, 0px 0px 10px #66fcf1;
  border: 1px solid transparent;
}
.Emoji-Editor-Accent-Sakura {
  background-color: #ed207b;
}
.Emoji-Editor-Accent-Sakura:hover {
  box-shadow: 0px 0px 5px #ed207b, 0px 0px 10px #ed207b, 0px 0px 20px #ed207b, 0px 0px 10px #ed207b;
  border: 1px solid transparent;
}
.Emoji-Editor-Accent-MonMon {
  background-color: #00e59b;
}
.Emoji-Editor-Accent-MonMon:hover {
  box-shadow: 0px 0px 5px #00e59b, 0px 0px 10px #00e59b, 0px 0px 20px #00e59b, 0px 0px 10px #00e59b;
  border: 1px solid transparent;
}
.Emoji-Editor-Accent-Blaze {
  background-color: #ff6600;
}
.Emoji-Editor-Accent-Blaze:hover {
  box-shadow: 0px 0px 5px #ff6600, 0px 0px 10px #ff6600, 0px 0px 20px #ff6600, 0px 0px 10px #ff6600;
  border: 1px solid transparent;
}
.Emoji-Editor-Accent-Purp {
  background-color: #9146ff;
}
.Emoji-Editor-Accent-Purp:hover {
  box-shadow: 0px 0px 5px #9146ff, 0px 0px 10px #9146ff, 0px 0px 20px #9146ff, 0px 0px 10px #9146ff;
  border: 1px solid transparent;
}
