.Image-Editor-Item-Add {
  width: 100%;
  aspect-ratio: 16/12;
  cursor: pointer;
  display: flex;
  background: var(--background-secondary);
  transition: background 0.5s;
  box-shadow: var(--shadow-inset);
}
.Image-Editor-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Image-Editor-Item-Add:hover {
  outline: solid 2px var(--primary-accent);
  background: var(--background-primary);
}
.Image-Editor-Item-Add:hover > svg {
  color: var(--font-color);
}
