.Profile-Chat-Container {
  width: 350px;
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .Profile-Chat-Container {
    display: none;
  }
}
.Chat-Container {
  margin-right: 0;
  background-color: var(--background-secondary);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  height: 100%;
  position: relative;
}
