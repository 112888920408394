.Nav-Mobile-Profile-Menu-Container {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  background: var(--background-primary);
  box-shadow: var(--shadow-inset);
  z-index: 999;
  animation: slideInUp 500ms;
  overflow-y: scroll;
}
.Nav-Mobile-Profile-Menu-Header {
  padding: auto;
  position: relative;
  padding: 50px 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}
.Nav-Mobile-Profile-Menu-Header-Inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Nav-Mobile-Profile-Menu-Header-BG {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top right;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(80%);
}
.Nav-Mobile-Profile-Menu-Header-Inner-Back {
  margin-right: 10px;
  cursor: pointer;
}
.Nav-Mobile-Profile-Menu-Header-Inner-Back > svg {
  font-size: 3rem;
  filter: drop-shadow(2px 2px 2px black);
}
.Nav-Mobile-Profile-Menu-Header-Inner-User-Info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-shadow: 1px 1px 1px black;
  width: calc(100% - 124px);
}
.Nav-Mobile-Profile-Menu-Header-Inner-User-Info-Top {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
}
.Nav-Mobile-Profile-Menu-Header-Inner-User-Info-Top * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Nav-Mobile-Profile-Menu-View-Profile-Button {
  height: 41px;
  margin-top: 5px;
  background: var(--background-secondary);
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 18px;
  cursor: pointer;
  transition: all 0.2s;
  box-sizing: border-box;
  flex-grow: 1;
  box-shadow: var(--shadow-inset);
  opacity: 0.9;
}

.Nav-Mobile-Profile-Menu-User-Info {
  background: var(--background-primary);
  box-shadow: var(--shadow-inset);
  padding: 14px 10px;
}

.Nav-Mobile-Profile-Menu-User-Info-Aggregates {
  display: flex;
  justify-content: space-evenly;
}
.Nav-Mobile-Profile-Menu-User-Info-Aggregate {
  background: var(--background-secondary);
  box-shadow: var(--shadow-inset);
  padding: 10px 10px;
  width: 100px;
  font-size: 1.2rem;
  font-weight: 600;
}
.Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top {
  color: var(--font-color);
}
.Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom {
  color: var(--font-color-alt);
}

.Nav-Mobile-Profile-Menu-User-Info-Socials {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.Nav-Mobile-Profile-Menu-Options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Nav-Mobile-Profile-Menu-Option {
  color: var(--font-color-alt);
  width: 100%;
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
  font-size: 1.5rem;
  font-weight: 600;
  box-sizing: border-box;
}
.Nav-Mobile-Profile-Menu-Option:last-child {
  border: none;
  color: rgb(255, 120, 120);
}

@keyframes slideInUp {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}
