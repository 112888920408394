.Info-Block-Text {
  width: 100%;
  min-height: 50px;
  height: 58px;
  background-color: var(--background-secondary);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: var(--shadow-inset);
}
.Info-Block-Text:focus {
  outline: 1px solid var(--primary-accent);
}
.Info-Block-Text:disabled {
  border-color: rgb(79, 84, 92);
  background: transparent;

  color: rgb(114, 118, 125);
}
.Info-Block-Text::placeholder {
  color: rgb(185, 187, 190);
}
.Info-Block-Text:invalid {
  box-shadow: none;
}

.Info-Header-Text {
  color: var(--font-color);
  font-size: 1.5rem;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}
