.Video-Block-Item-Editbutton {
  position: absolute;
  top: 50%;
  left: calc(50% - 27px);
  background: #18191c;
  padding: 10px 12px;
  cursor: pointer;
}
.Video-Block-Item-Editbutton > svg {
  color: white !important;
  font-size: 3rem;
}

.Video-Editor-Item-Add {
  width: 100%;
  cursor: pointer;
  display: flex;
  background: var(--background-secondary);
  transition: background 0.5s;
  box-shadow: var(--shadow-inset);
  aspect-ratio: 16/12;
}
.Video-Editor-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Video-Editor-Item-Add:hover {
  outline: solid 2px var(--primary-accent);
  background: var(--background-primary);
}
.Video-Editor-Item-Add:hover > svg {
  color: var(--font-color);
}
@media screen and (max-width: 650px) {
  .Video-Editor-Item-Add {
    margin-bottom: 10px;
    aspect-ratio: 16/12;
  }
}

.Video-Block-Container {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.Video-Block-Container-Alt {
  grid-column: 1 / 2;
  display: grid;
  gap: 10px;
}
.Video-Block-Container-Alt-2 {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}
.Video-Block-Item {
  position: relative;
  width: 100%;
  border-radius: 0px;
  border-left: 2px solid var(--border-color);
  padding: 0.5rem 1rem 1rem 0.75rem;
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  box-shadow: var(--shadow-inset);
  background: var(--background-secondary);
}
.Video-Block-Item-Twitch {
  border-color: var(--twitch-purple) !important;
}
.Video-Block-Item-Youtube {
  border-color: var(--youtube-red) !important;
}
.Video-Block-Link {
  min-width: 0px;
  display: inline-block;
  margin: 8px 0px 0px;
  color: var(--primary-accent);
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  font-weight: 600;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.Video-Block-Link:hover {
  text-decoration: underline;
}
.Video-Block-Desc {
  color: var(--font-color);
  min-width: 0px;
  margin: 8px 0px 0px;
  text-align: left;
  font-size: 16px;
}
.Video-Block-Item-Image {
  min-width: 0px;
  width: 100%;
  margin: 16px 0px 0px;
  border-radius: 0px;
  cursor: pointer;
}
.Video-Block-Item-Embed {
  min-width: 0px;
  aspect-ratio: 16/9;
  width: 100%;
  margin: 16px 0px 0px;
  border-radius: 0px;
  cursor: pointer;
}
.Video-Block-Item-Playbutton {
  position: absolute;
  top: 50%;
  left: 45%;
  background: #18191c;
  border-radius: 16px;
  padding: 10px 12px;
  cursor: pointer;
}
.Video-Block-Item-Playbutton > svg {
  color: var(--font-color);
  font-size: 3rem;
}
