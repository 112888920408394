.media-controls-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* prevent blocking lower elements (e.g., gestures). */
  /* pointer-events: none; */
  /** place above poster (optional). */
  z-index: 1;
}

.media-controls-group {
  position: relative;
  z-index: 1;
  display: flex;
  width: calc(100% - 100px);
  height: 50px;
  opacity: 1;
  /* padding: 8px; */
  margin-bottom: 10px;
  transition: opacity 200ms ease;
  box-sizing: border-box;
  /** prevent clicks triggering lower elements. */
  pointer-events: auto;
  box-shadow: var(--shadow-inset);
  /* border-radius: 5px; */
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(20px) saturate(1.5) brightness(1.2);
  backdrop-filter: blur(20px) saturate(1.5) brightness(1.2);
}

/* Avoid double controls on iOS when in fullscreen. */
media-player[data-ios-controls] .media-controls-group,
  /* Hide controls if media is not ready for playback, or user is idle. */
  media-player[data-user-idle] .media-controls-group,
  media-player:not([data-can-play]) .media-controls-group {
  opacity: 0;
  pointer-events: none;
}

/* CSS vars are available for simple customization. */
media-volume-slider {
  /* Default values are shown below. */
  width: 100px;
  --media-slider-height: 48px;
  --media-slider-thumb-size: 0px;
  --media-slider-focused-thumb-size: 0;
  --media-slider-track-height: 4px;
  --media-slider-focused-track-height: calc(var(--track-height) * 1.25);
}

media-time-slider {
  /* Default values are shown below. */
  --media-slider-height: 48px;
  --media-slider-thumb-size: 0px;
  --media-slider-focused-thumb-size: 0;
  --media-slider-track-height: 4px;
  --media-slider-focused-track-height: calc(var(--track-height) * 1.25);
  margin-left: 10px;
}

.media-buffering-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* prevent blocking lower elements (e.g., gestures). */
  pointer-events: none;
  /** place above poster (optional). */
  z-index: 1;
}

.media-buffering-icon {
  width: 84px;
  height: 84px;
  color: var(--primary-accent);
  opacity: 0;
  transition: opacity 200ms ease;
}

.media-buffering-track {
  opacity: 0.25;
}

.media-buffering-track-fill {
  opacity: 0.75;
  stroke-dasharray: 100;
  stroke-dashoffset: 50;
}

/* Show buffering indicator while media is not ready, or buffering. */
media-player:not([data-can-play]) .media-buffering-icon,
media-player[data-waiting] .media-buffering-icon {
  opacity: 1;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
