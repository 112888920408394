.Avatar-Editor-Container {
  margin-top: 30px;
}
.Avatar-Editor-Container > h1 {
  color: var(--font-color);
}
.Avatar-Editor-Flex {
  display: flex;
}
.Avatar-Preview-Container {
  display: flex;
  align-items: baseline;
  padding: 10px 40px;
  margin: 10px;
  margin-left: 0;
  border-radius: 8px;
}
.Avatar-Preview-Item {
  margin-left: 30px;
}
.Avatar-Preview-Item:first-child {
  margin-left: 0;
}

.Past-Avatar-Container-Inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.Past-Avatar-Container {
  margin: 10px 40px;
  padding: 10px 20px;
  position: relative;
  background: var(--background-secondary);
  color: var(--font-color);
  box-shadow: var(--shadow-inset);
}
.Past-Avatar-Container > h2 {
  margin-top: 0;
  text-align: left;
}
.Past-Avatar-Item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Past-Avatar-Preview {
  max-width: 120px;
  width: 100%;
  aspect-ratio: 1/1;
  height: unset;
  cursor: pointer;
  border: none;
  box-shadow: var(--shadow);
  filter: brightness(65%);
}
.Past-Avatar-Preview:hover {
  filter: brightness(100%);
}
.Past-Avatar-Preview-Image {
  width: 100%;
  aspect-ratio: 1/1;
  height: unset;
}

.Avatar-Editor-Crop-Image-Container {
  display: flex;
  background: var(--background-secondary);
  box-shadow: var(--shadow-inset);
  padding: 10px 10px;
  margin: 10px 40px;
}
.Avatar-Editor-Crop-Image-Inner-Container {
  margin-left: auto;
  margin-right: auto;
}

.Avatar-Editor-Crop-Image-Preview {
  max-height: 70vh !important;
  border-radius: 4px !important;
}

.Upload-Avatar-Info {
  margin-left: 20px;
  text-align: left;
}
.Upload-Avatar-Info > h1 {
  color: var(--font-color);
  margin-bottom: 0;
}

.Upload-Avatar-Info > h2 {
  color: var(--font-color-alt);
}
.Upload-Avatar-Button {
  display: flex;
  height: 50px;
}
.Upload-Avatar-Button > button {
  margin-top: 10px;
  min-width: 150px;
}
.Upload-Avatar-Button > button {
  position: relative;
}
.Upload-Avatar-Button > button:hover {
  background-color: var(--background-primary);
  color: var(--primary-accent);
}
.Upload-Avatar-Button > button * {
  cursor: pointer;
}
.Upload-Avatar-Button > button::before,
.Upload-Avatar-Button > button::after,
.Upload-Avatar-Button > button > span::before,
.Upload-Avatar-Button > button > span::after {
  display: block;
  content: '';
  width: 6%;
  height: 20%;
  position: absolute;
}
.Upload-Avatar-Button > button::before {
  top: -2px;
  left: -2px;

  transition: 1s all;
}
.Upload-Avatar-Button > button::after {
  top: -2px;
  right: -2px;

  transition: 1s all;
}
.Upload-Avatar-Button > button:hover::before {
  height: 100%;
  border-top: 1px solid var(--seiso-border);
  border-left: 1px solid var(--seiso-border);
}
.Upload-Avatar-Button > button:hover::after {
  width: 90%;
  border-top: 1px solid var(--seiso-border);
  border-right: 1px solid var(--seiso-border);
}

.Upload-Avatar-Button > button > span::before {
  bottom: -1px;
  left: -2px;

  transition: 1s all;
}
.Upload-Avatar-Button > button > span::after {
  bottom: -1px;
  right: -2px;

  transition: 1s all;
}
.Upload-Avatar-Button > button:hover > span::before {
  border-bottom: 1px solid var(--seiso-border);
  border-left: 1px solid var(--seiso-border);
  width: 90%;
}
.Upload-Avatar-Button > button:hover > span::after {
  border-bottom: 1px solid var(--seiso-border);
  border-right: 1px solid var(--seiso-border);
  height: 100%;
}

@media screen and (max-width: 950px) {
  .Upload-Avatar-Info > h1 {
    display: none;
  }
  .Avatar-Editor-Container {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 640px) {
  .Avatar-Editor-Flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .Past-Avatar-Container {
    margin: 0;
    margin-top: 10px;
  }
  .Avatar-Preview-Container {
    padding: 0;
    margin: 0;
    justify-content: space-evenly;
    width: 100%;
  }
  .Past-Avatar-Preview {
    max-width: 240px;
  }
  .Past-Avatar-Container-Inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .Upload-Avatar-Info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
}
