.Message-Chatbox-Input-Container {
  width: 100%;
  min-height: 50px;
  background: var(--background-secondary);
  box-sizing: border-box;
  max-height: 50vh;
  flex-shrink: 0;
  padding: 10px;
}
.Message-Chatbox-Input-Container-Inner > .Seiso-Editor-Container > .Typeahead-Popover {
  bottom: 100%;
}
.Message-Chatbox-Input-Container-Bottom {
  display: flex;
}
.Message-Chatbox-Input-Container-Inner {
  background: var(--background-primary);
  border: 1px solid black;
  width: calc(100% - 126px);
  position: relative;
  display: flex;
  display: flex;
  flex-direction: column;
}
.Message-Chatbox-Input {
  background: transparent;
  min-height: 42px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  border: none;
  align-self: flex-start;
  resize: none;
  font-size: 1.5rem;
  vertical-align: middle;
  padding: 10px 9px;
  resize: none;
  text-align: left;
  unicode-bidi: plaintext;
  overflow-wrap: break-word;
  cursor: text;
  box-shadow: var(--shadow-inset);
}
.Message-Chatbox-Input:focus {
  border: none;
  outline: none;
}
.Message-Chatbox-Send {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--background-secondary);
  width: 42px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  color: var(--font-color-alt);
  cursor: pointer;
  border-left: 1px solid black;
}
.Message-Chatbox-Send:hover {
  color: var(--primary-accent);
}
.Message-Chatbox-Input-Options {
  border: 1px solid black;
  box-sizing: border-box;
  height: 42px;
  width: 126px;
  margin-left: 10px;
  display: flex;
}
.Message-Chatbox-Input-Option {
  box-sizing: border-box;
  width: 42px;
  height: 100%;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color-alt);
  cursor: pointer;
}
.Message-Chatbox-Input-Option:last-child {
  border: none;
}
.Message-Chatbox-Input-Option:hover {
  color: var(--primary-accent);
}
.Create-Message-Image-Container {
  position: relative;
  width: calc(25% - 10px);
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  height: 150px;
  background: var(--background-primary);
  box-shadow: var(--shadow-inset);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Create-Message-Image {
  min-width: 0px;
  max-width: 100%;
  max-height: 100%;
  margin: 0px 0px 0px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  align-self: flex-start;
  margin: auto;
}
.Create-Message-Upload-Close-Icon {
  position: absolute;
  color: rgb(255, 120, 120);
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  top: 0px;
  left: 0px;
  height: 2.5rem !important;
  width: 2.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
  box-shadow: var(--shadow-inset);
}
.Create-Message-Upload-Close-Icon:hover {
  background-color: rgba(50, 50, 50, 0.5);
}
.Create-Message-Upload-Edit-Icon {
  position: absolute;
  color: var(--font-color);
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  right: 0px;
  top: 0px;
  height: 2.5rem !important;
  width: 2.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
  box-shadow: var(--shadow-inset);
}
.Create-Message-Upload-Edit-Icon:hover {
  background-color: rgba(50, 50, 50, 0.5);
}
