.Nav-Tabs {
  width: 100%;
  position: sticky;
  top: 100px;
  box-shadow: var(--shadow-inset);
  display: flex;
  justify-content: space-evenly;
}
.Nav-Tab {
  height: 100%;
  width: 100%;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 1.5rem;
  color: var(--font-color-alt);
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
}
.Nav-Tab:hover {
  border-bottom: 1px solid var(--primary-accent);
  background: var(--background-secondary);
}
.Nav-Tab-Active {
  border-bottom: 1px solid var(--primary-accent);
}
