.Video-Element-Container {
  text-align: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.Video-Element-Placeholder {
  background: black;
  box-shadow: var(--shadow);
  max-width: -webkit-fill-available;
}
.Video-Placeholder-Loading {
  position: absolute;
  top: 50%;
  padding: 0;
}
