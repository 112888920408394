.EmojiPickerReact .epr-emoji-variation-picker {
  position: absolute;
  top: 0;
  right: 15px;
  left: 15px;
  padding: 5px;
  height: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  top: -100%;
  border: 1px solid var(--epr-picker-border-color);
  height: var(--epr-emoji-variation-picker-height);
  z-index: var(--epr-skin-variation-picker-z-index);
  background: var(--epr-emoji-variation-picker-bg-color);
  transform: scale(0.9);
  transition: transform 0.1s ease-out, opacity 0.2s ease-out;
}

.EmojiPickerReact .epr-emoji-variation-picker.pointing-up {
  transform-origin: center 0%;
  transform: scale(0.9);
}

.EmojiPickerReact .epr-emoji-variation-picker.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: scale(1);
}
.EmojiPickerReact
  .epr-emoji-variation-picker
  .EmojiPickerReact
  .epr-emoji-variation-picker
  button.epr-emoji:hover {
  background: none;
}

.EmojiPickerReact .epr-emoji-variation-picker .epr-emoji-pointer {
  content: '';
  position: absolute;
  top: -10px;
  width: 25px;
  height: 15px;
}

.EmojiPickerReact .epr-emoji-variation-picker .epr-emoji-pointer {
  background-image: svg-load(./svg/triangle.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 50px 15px;
}

.EmojiPickerReact.epr-dark-theme
  .epr-emoji-variation-picker
  .epr-emoji-pointer {
  background-position: -25px 0;
}

.EmojiPickerReact .epr-emoji-variation-picker .epr-emoji-pointer {
  top: 100%;
  transform: translateX(-18px);
}

.EmojiPickerReact .epr-emoji-variation-picker.pointing-up .epr-emoji-pointer {
  top: 0;
  transform: rotate(180deg) translateY(100%) translateX(18px);
}
