.Messages-Container {
  width: 100%;
  max-width: 1280px;
  background: var(--background-primary);
  height: calc(100vh - 50px);
  display: flex;
}
.Message-List {
  width: 340px;
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}
.Message-List-Header {
  text-align: left;
  font-size: 1.3rem;
  padding: 14px 10px;
  border-bottom: 2px solid var(--primary-accent);
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  position: relative;
}
.Message-List-Header > svg {
  font-size: 2.5rem;
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--font-color-alt);
  cursor: pointer;
}
.Message-List-Header > svg:hover {
  color: var(--font-color);
}
.Message-List-Item {
  padding: 12px;
  display: flex;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  box-shadow: var(--shadow-inset);
}
.Message-List-Item:last-child {
  border: none;
}
.Message-List-Item:hover {
  background: var(--background-secondary);
}
.Message-List-Item-Active {
  background: var(--background-secondary);
}
.Message-List-Item-Info {
  margin-left: 10px;
  text-align: left;
  width: calc(100% - 61px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Message-List-Item-Displayname {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--font-color-alt);
}
.Message-List-Item-Displayname-Active {
  color: var(--font-color);
}
.Message-List-Item-Subtext {
  color: var(--font-color-alt);
  font-weight: 600;
  font-size: 1.1rem;
  vertical-align: 25%;
  margin-top: 3px;
}
.Message-List-Item-Subtext-Active {
  color: var(--font-color);
}
.Message-List-Item-Subtext * > img {
  filter: brightness(70%);
}
.Message-List-Item-Subtext-Active * > img {
  filter: brightness(100%);
}
.Message-List-Item-Subtext * {
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Message-List-Item-Subtext > p {
  margin: 0;
}

.Message-Chatbox {
  position: relative;
  width: calc(100% - 340px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Message-Chatbox-List {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10px;
}
.Message-Chatbox-List-Item {
  margin-top: 20px;
  padding: 2px 10px;
}
.Message-Chatbox-List-Item:first-child {
  margin-bottom: 10px;
}
.Message-Chatbox-List-Item:hover {
  background: rgba(24, 24, 27, 0.5);
}
.Message-Chatbox-List-Item-Upper {
  display: flex;
}
.Message-Chatbox-List-Item-Username {
  font-size: 1.6rem;
  font-weight: 700;
  margin-left: 5px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: 22px;
}
.Message-Chatbox-List-Item-Date {
  color: var(--font-color-alt);
  font-size: 1.2rem;
  margin-left: 5px;
}
.Message-Chatbox-List-Item-Lower {
  color: var(--font-color-alt);
  text-align: left;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0;
  white-space: pre-wrap;
  margin-left: 50px;
  margin-top: -25px;
  word-wrap: break-word;
}
.Message-Chatbox-List-Item-Lower-Alt {
  margin-top: 0;
  padding: 2px 10px;
  margin: 0;
  padding-left: 60px;
}
.Message-Chatbox-List-Item-Lower-Alt:hover {
  background: rgba(24, 24, 27, 0.5);
}
.Message-Chatbox-List-Item-Lower * > p {
  margin: 0;
  align-items: center;
}
.Message-Image {
  min-width: 0px;
  max-width: 100%;
  max-height: 250px;
  margin: 0px 0px 0px;
  border-radius: 0px;
  border: 1px solid black;
  cursor: pointer;
  align-self: flex-start;
  box-shadow: var(--shadow);
}

.Select-Chat-Notification {
  font-size: 2rem;
  color: var(--font-color-alt);
  font-weight: 600;
  border: 2px dashed var(--border-color);
  max-width: 300px;
  width: 100%;
  justify-self: center;
  margin: auto;
  margin-top: 35%;
  padding: 40px;
  box-shadow: var(--shadow-inset);
}

.New-Message-Button {
  height: 41px;
  margin-top: 30px;
  background: var(--background-secondary);
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 20px;
  cursor: pointer;
  box-shadow: var(--shadow);
  transition: all 0.2s;
}
.New-Message-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
}

.Messages-Mobile-Menu-Button {
  color: white;
  position: fixed;
  top: 60px;
  left: 10px;
  box-shadow: var(--shadow-inset);
  background: var(--background-primary);
  align-items: center;
  padding: 10px;
  cursor: pointer;
  display: none;
  z-index: 1;
}
.Messages-Mobile-Menu-Button > svg {
  font-size: 2.5rem;
}

@media screen and (max-width: 950px) {
  .Message-Chatbox {
    width: unset;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    transition: all 250ms;
  }
  .Message-List {
    position: fixed;
    left: -340px;
    top: 50px;
    bottom: 0;
    z-index: 2;
    transition: all 250ms;
    background: var(--background-primary);
  }

  .Messages-Mobile-Menu-Button {
    display: flex;
  }

  .Message-Chatbox-Mobile-Menu-Open {
    left: 340px;
    right: -340px;
  }
  .Message-List-Mobile-Menu-Open {
    left: 0;
  }
}
