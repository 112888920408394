.Post-Container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0px;
  position: relative;
  transition: all 0.3s;
  padding: 6px 12px;
}
@media screen and (max-width: 650px) {
  .Post-Container {
    padding: 6px 4px;
  }
}
.Focused-Post-Container {
  padding: 0;
}
.Focused-Post-Container > .Post-Container-Inner:hover {
  background: unset;
  cursor: unset;
}
.Quote-Post-Container {
  margin-top: 10px;
  padding: 6px 0px;
}
.Quote-Post-Container > .Post-Container-Inner:hover {
  background: rgba(0, 0, 0, 0.5);
}
.Post-Container:first-child {
  border-top: none;
}

.Post-Container-Inner {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  box-shadow: var(--shadow-inset);
  padding: 12px 12px;
  border-bottom: 2px solid var(--border-color);
}
@media (hover: hover) {
  .Post-Container-Inner:hover {
    background: var(--background-secondary);
  }
}
.Post-Shared-By {
  text-align: left;
  margin-left: 0px;
  color: var(--font-color-alt);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 900;
  width: fit-content;
}
.Post-Shared-By * {
  pointer-events: none;
}
@media (hover: hover) {
  .Post-Shared-By:hover {
    text-decoration: underline;
  }
}
.Post-Shared-By > svg {
  margin: 0;
  margin-right: 5px;
  margin-top: -3px;
  font-size: 1.7rem;
}
.Post-Shared-By-Emoji {
  height: 1.3rem;
  width: 1.3rem;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -15%;
}
.Post-Body {
  display: flex;
  flex-direction: row;
}
.Post-Left {
  flex-basis: 50px;
  margin-right: 8px;
}

.PFP {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  margin: auto;
}

.Post-Right {
  width: calc(100% - 62px);
}

.Post-Header {
  margin-bottom: 2px;
  display: flex;
  align-items: flex-start;
}

.Post-Info {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  max-width: calc(100% - 90px);
}

.Post-User-Info {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Post-Displayname {
  color: var(--font-color);
  overflow-wrap: break-word;
  font-size: 1.6rem;
  font-weight: 900;
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.Post-Displayname > .Verified-User-Check {
  overflow: unset;
}
@media (hover: hover) {
  .Post-Displayname:hover {
    text-decoration: underline;
  }
}

.Post-Displayname * {
  pointer-events: none;
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Post-Displayname-Emoji {
  height: 2rem;
  width: 2rem;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -20%;
}

.Post-Username {
  color: var(--font-color-alt);
  overflow-wrap: break-word;
  margin-left: 4px;
  font-size: 1.3rem;
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
}

.Post-Timestamp {
  color: var(--font-color-alt) !important;
  overflow-wrap: break-word;
  margin-left: 6px;
  text-decoration: none;
  font-family: monospace;
  font-size: 1.3rem;
  font-weight: 900;
}
@media (hover: hover) {
  .Post-Timestamp:hover {
    text-decoration: underline;
  }
}
.Post-Timestamp > time {
  color: var(--font-color-alt) !important;
}
.Post-Menu-Button {
  background: none;
  border: none;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  color: var(--font-color-alt);
  cursor: pointer;
}
.Post-Menu-Button > svg {
  font-size: 2rem;
}
@media (hover: hover) {
  .Post-Menu-Button:hover {
    color: var(--font-color);
  }
}

.Reply-Indicator {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  color: var(--font-color-alt);
  margin-bottom: 2px;
}

.Post-Content {
  position: relative;
}

.Post-Content-Content-Warning {
  backdrop-filter: blur(40px);
  background: rgba(15, 15, 15, 0.75);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.Post-Content-Content-Warning-Show-Button {
  background: var(--background-primary);
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--font-color-alt);
  font-size: 1.5rem;
  font-weight: 900;
  padding: 10px 30px;
  box-shadow: var(--shadow-inset);
}
.Post-Content-Content-Warning-Show-Button:hover {
  color: var(--font-color);
}

.Post-Text {
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--font-color);
  text-align: left;
  word-wrap: break-word;
}

.Post-Text * > img {
  max-width: 100%;
}
.Post-Text * > .Create-Post-Custom-Emoji {
  display: unset;
}
.Post-Text * > p {
  display: inline;
  margin: 0;
}

.Post-Text > span {
  overflow-wrap: break-word;
}

.Post-Emoji {
  height: 2rem;
  width: 2rem;
  margin-left: 1.5px;
  margin-right: 1.5px;
  vertical-align: -20%;
  display: unset !important;
}
.Post-Custom-Emoji {
  height: 2.8rem;
  width: 2.8rem;
  margin-left: 1.5px;
  margin-right: 1.5px;
  vertical-align: -20%;
  display: unset !important;
}

.Post-Options {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  padding-right: 62px;
  position: relative;
}
.Post-Option {
  background: none;
  border: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  cursor: pointer;
}
.Post-Option > svg {
  font-size: 2rem;
  margin-right: 5px;
  padding: 4px;
}
.Post-Option * {
  color: var(--font-color-alt);
}
@media (hover: hover) {
  .Post-Option:hover * {
    color: var(--font-color);
  }
}

.Post-Option > span {
  min-width: 50px;
}
@media (hover: hover) {
  .Post-Option-Active:hover * {
    color: rgb(255, 120, 120) !important;
  }
}

.Post-Option-Active > .Break-Heart {
  display: none;
}
@media (hover: hover) {
  .Post-Option-Active:hover > .Break-Heart {
    display: unset !important;
  }
  .Post-Option-Active:hover > .Full-Heart {
    display: none;
  }
}
.Post-Option-Active > .Remove-Bookmark {
  display: none;
}
@media (hover: hover) {
  .Post-Option-Active:hover > .Remove-Bookmark {
    display: unset !important;
  }
  .Post-Option-Active:hover > .Full-Bookmark {
    display: none;
  }
}

.Post-Non-Text {
  margin-top: 12px;
  display: flex;
  /* flex-direction: column; */
  position: relative;
}

.Post-Image-Container {
  align-self: flex-start;
  border: 1px solid var(--border-color);
  border-radius: 0px;
  display: inline-block;
  overflow: hidden;
}
.Post-Video {
  width: 100%;
  max-height: 600px;
  min-height: 600px;
  align-self: flex-start;
  border-radius: 0px;
  display: inline-block;
  overflow: hidden;
  background-color: black;
  border: 1px solid var(--border-color);
}
.Post-Video:focus {
  border: 1px solid var(--border-color);
  outline: none;
}
.Post-Video * video {
  max-height: 100%;
}
.Post-Video-Inner {
  width: 100%;
  max-height: 600px;
  align-self: flex-start;
  border-radius: 0px;
  display: inline-block;
  overflow: hidden;
  background-color: black;
  border: 1px solid var(--border-color);
}

.Post-Image-Single {
  align-self: flex-start;
  position: relative;
  width: 100%;
}

.Post-Images-Grid {
  align-self: flex-start;
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  max-height: 600px;
  border-radius: 0px;
  overflow: hidden;
  border: 1px solid var(--border-color);
  position: relative;
  width: 100%;
}
.Post-Images-Grid-Item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  grid-row: span 2 / auto;
}
.Post-Images-Grid-Item-Alt {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
}
.Post-Image {
  min-width: 0px;
  max-width: 100%;
  max-height: 600px;
  margin: 0px 0px 0px;
  border-radius: 0px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  align-self: flex-start;
}
.Post-Image-Alt {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.Post-Gif {
  min-width: 0px;
  max-width: 100%;
  max-height: 600px;
  margin: 0px 0px 0px;
  border-radius: 0px;
  border: 1px solid var(--border-color);
  cursor: pointer;
}

.Post-Mention {
  color: var(--primary-accent);
}
@media (hover: hover) {
  .Post-Mention:hover {
    text-decoration: underline;
  }
}
.Post-Hashtag {
  color: var(--primary-accent);
}
@media (hover: hover) {
  .Post-Hashtag:hover {
    text-decoration: underline;
  }
}
.Post-Link {
  color: var(--primary-accent);
}
@media (hover: hover) {
  .Post-Link:hover {
    text-decoration: underline;
  }
}
