.Profile-Background-Editor-Container {
  margin-top: 30px;
}
.Profile-Background-Editor-Container > h1 {
  color: var(--font-color);
}

.Profile-Background-Editor-Flex {
  display: flex;
}
.Profile-Background-Preview-Container {
  padding: 10px 10px;
  margin: 10px;
  margin-top: 20px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}

.Upload-Profile-Background-Info {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}
.Upload-Profile-Background-Info > div > h1 {
  color: var(--font-color);
  margin: 0;
}

.Upload-Profile-Background-Info > div > h2 {
  color: var(--font-color-alt);
}

.Past-Profile-Background-Container {
  margin: 10px 20px;
  margin-top: 20px;
  background: var(--background-secondary);
  padding: 10px 10px;
  position: relative;
  color: var(--font-color);
  box-shadow: var(--shadow-inset);
}
.Past-Profile-Background-Container > h2 {
  text-align: left;
}
.Past-Profile-Background-Item {
  aspect-ratio: 3.5/1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10px;
  filter: brightness(65%);
}
.Past-Profile-Background-Item:hover {
  cursor: pointer;
  filter: brightness(100%);
}
.Profile-Background-Preview-Video {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16/9;
  box-shadow: var(--shadow);
  background: var(--background-secondary);
  object-fit: cover;
  object-position: top;
}
.Profile-Background-Preview-Image {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16/9;
  box-shadow: var(--shadow);
  background: var(--background-secondary);
}
.Upload-Profile-Background-Button {
  margin-left: auto;
  margin-right: 10px;
}

@media screen and (max-width: 950px) {
  .Upload-Profile-Background-Info > div > h1 {
    display: none;
  }
  .Profile-Background-Editor-Container {
    margin: 0;
  }
}
@media screen and (max-width: 640px) {
  .Profile-Background-Preview-Video {
    max-width: 100%;
  }
}
