.Button {
  padding: 9px 30px;
  font-size: 1.6rem;
  background: var(--background-primary);
  font-weight: 600;
  cursor: pointer;
  border: 1px solid black;
  box-shadow: var(--shadow);
  box-sizing: content-box;
  transition: all 0.5s;
  position: relative;
}
.Button.Primary {
  color: var(--font-color);
}
.Button.Secondary {
  color: var(--font-color-alt);
}
.Button.Transparent {
  color: var(--font-color);
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
}
.Button.Reverse {
  box-shadow: var(--shadow-inset);
  border: none;
  outline: none;
  color: var(--font-color);
  background-color: transparent;
}
.Button.Reverse:hover {
  box-shadow: var(--shadow);
  background-color: var(--background-secondary);
}
.Button:disabled {
  cursor: not-allowed;
}
@media (hover: hover) {
  .Button:hover {
    color: var(--primary-accent);
    opacity: 0.9;
  }
}
