.Profile-Menu {
  position: absolute;
  right: 14px;
  top: 10px;
  z-index: 1;
  width: 250px;
  border: 1px solid #000;
  box-shadow: var(--shadow);
  background-color: var(--background-primary);
}
.Profile-Menu-Options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Profile-Menu-Option {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--font-color-alt);
  cursor: pointer;
  border-bottom: 1px solid black;
}
.Profile-Menu-Option:hover {
  color: white;
  background: var(--background-secondary);
}
.Profile-Menu-Option:last-child {
  border: none;
}
.Profile-Menu-Option-Red {
  color: rgb(255, 120, 120);
}
.Profile-Menu-Option-Red:hover {
  color: rgb(232, 48, 48);
}
