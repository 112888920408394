.New-Message-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  z-index: 2;
  align-items: center;
  justify-content: center;
}
.New-Message-Modal-Container {
  width: 740px;
  box-sizing: border-box;
  padding: 0px 10px;
  padding-top: 10px;
  cursor: pointer;
  box-shadow: var(--shadow-inset);
  background: var(--background-primary);
  margin: auto;
  height: calc(80vh - 60px);
  overflow: scroll;
}
.New-Message-Modal-Container > h2 {
  font-weight: 600;
  text-transform: uppercase;
}
.New-Message-Modal-Search {
  width: 100%;
  background-color: var(--background-secondary);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: var(--shadow-inset);
}
.User-Output-Item {
  width: 100%;
}
