.Roadmap-Container {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  max-width: 1000px;
  width: 100%;
}
.Roadmap-Text-Container {
  width: 100%;
  background: var(--background-primary);
  backdrop-filter: blur(6px);
  padding: 1rem 1rem 1rem 1rem;
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  box-shadow: var(--shadow-inset);
  box-sizing: border-box;
}
.Roadmap-Text {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  font-size: 1.5rem;
  color: var(--font-color);
}
