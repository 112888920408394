.Nav-Back-Button-Header-Top {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  background: rgba(15, 15, 15, 0.75);
  backdrop-filter: blur(12px);
  top: 50px;
  z-index: 2;
}
.Nav-Back-Button-Header-Back-Button {
  color: var(--font-color-alt);
  margin-right: 5px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.Nav-Back-Button-Header-Back-Button > svg {
  font-size: 2.5rem;
}
.Nav-Back-Button-Header-Back-Button > svg:hover {
  color: var(--font-color);
}
.Nav-Back-Button-Header-Title {
  text-decoration: none;
  color: var(--font-color-alt);
  font-size: 1.8rem;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 10px;
  text-shadow: var(--text-shadow);
}
