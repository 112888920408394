.Banner-Editor-Container {
  margin-top: 30px;
}
.Banner-Editor-Container > h1 {
  color: var(--font-color);
}
.Banner-Editor-Flex {
  display: flex;
}
.Banner-Preview-Container {
  padding: 10px 10px;
  margin: 10px;
  margin-top: 20px;
}
.Banner-Preview {
  aspect-ratio: 3.5/1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  box-shadow: var(--shadow);
}
.Upload-Banner-Info {
  text-align: left;
  display: flex;
  width: 100%;
  align-items: center;
}
.Upload-Banner-Info > div > h1 {
  color: var(--font-color);
  margin: 0;
}
.Upload-Banner-Info > div > h2 {
  color: var(--font-color-alt);
}
.Past-Banner-Container {
  margin: 10px;
  background: var(--background-secondary);
  box-shadow: var(--shadow);
  padding: 10px 10px;
  position: relative;
  color: var(--font-color);
  box-shadow: var(--shadow-inset);
}
.Past-Banner-Container > h2 {
  text-align: left;
}
.Past-Banner-Item {
  aspect-ratio: 4/1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10px;
  filter: brightness(65%);
}
.Past-Banner-Item:hover {
  cursor: pointer;
  filter: brightness(100%);
}
.Upload-Banner-Button {
  margin-left: auto;
  margin-right: 10px;
}

@media screen and (max-width: 950px) {
  .Banner-Editor-Container {
    margin: 0;
  }
  .Upload-Banner-Info > div > h1 {
    display: none;
  }
}
