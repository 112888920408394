.Hashtag-Follow-Button {
  height: 41px;
  color: var(--font-color-alt);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box;
  margin-left: auto;
}
.Hashtag-Follow-Button:hover {
  background: var(--background-secondary);
  color: var(--primary-accent);
  box-shadow: var(--shadow);
}
