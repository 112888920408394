.Sidebar-Container {
  width: 360px;
  min-width: 360px;
  height: calc(100vh - 50px);
  padding: 5px;
  padding-top: 0;
  box-sizing: border-box;
  position: sticky;
  top: 50px;
  margin-left: 15px;
  overflow-y: scroll;
  overflow-x: visible;
}
.Sidebar-Container-Inner {
  margin-top: 10px;
}

.Sidebar-Container::-webkit-scrollbar {
  height: 12px;
  width: 14px !important;
  background: transparent;
  z-index: 12;
  overflow: visible;
}
.Sidebar-Container::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: transparent;

  z-index: 12;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: background-color 1s ease-in-out;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}
.Sidebar-Container:hover::-webkit-scrollbar-thumb {
  background-color: grey;
  box-shadow: 0px 0px 4px black inset;
}
.Sidebar-Container-Item {
  margin: 10px 5px;

  overflow: hidden;
  box-shadow: var(--shadow-inset);
  background: var(--background-primary);
  border: 1px solid var(--border-color);
}
.Sidebar-Container-Item-Header {
  text-align: left;
  margin: 10px;
  text-shadow: var(--text-shadow);
  font-size: 2rem;
  font-weight: 900;
  color: var(--font-color-alt);
}
.Sidebar-Showmore-Link {
  color: var(--font-color-alt);
  font-size: 1.3rem;
  display: block;
  text-decoration: none;
  padding: 11px 15px;
  text-align: center;
  font-weight: 900;
  border-top: 1px solid black;
  transition: all 0.3s;
  text-transform: uppercase;
  text-shadow: var(--text-shadow);
}
.Sidebar-Showmore-Link:hover {
  background-color: var(--background-secondary);
  color: var(--primary-accent);
  box-shadow: var(--shadow);
}

.Sidebar-Who2Follow-Item {
  display: flex;
  align-items: center;
  padding: 3px 6px 3px 6px;
  text-decoration: none;
}
.Sidebar-Who2Follow-Item:hover {
  background-color: var(--background-hover);
}
.Sidebar-Who2Follow-Name {
  margin-left: 10px;
  width: 150px;
  color: var(--font-color);
}
.Sidebar-Who2Follow-Name * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Sidebar-Who2Follow-Displayname {
  font-size: 1.8rem;
}
.Sidebar-Who2Follow-Displayname:hover {
  text-decoration: underline;
}
.Sidebar-Who2Follow-Username {
  font-size: 1.2rem;
  color: var(--font-color-alt);
  margin-top: -3px;
}
.Sidebar-Trending-Item {
  display: flex;
  padding: 6px 10px;
  text-decoration: none;
}
.Sidebar-Trending-Item:hover {
  background-color: var(--background-hover);
}
.Sidebar-Trending-Item-Info {
  width: 60%;
  text-align: left;
}
.Sidebar-Trending-Item-Info > h2,
h3 {
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--font-color);
}
.Sidebar-Trending-Item-Info > h3 {
  color: var(--font-color-alt);
}
.Sidebar-Trending-Item-Info > p {
  color: var(--font-color-alt);
  overflow: hidden;
  max-width: 100%;
  margin: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Sidebar-Trending-Item-Picture {
  width: 40%;
}
.Sidebar-Trending-Item-Picture > img {
  width: 100%;
}

.Sidebar-Welcome-Item {
  display: flex;
  padding: 6px 10px;
  text-decoration: none;
}
.Sidebar-Welcome-Item-Info {
  max-width: 100%;
  text-align: left;
}
.Sidebar-Welcome-Item-Info > h2,
h3 {
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--font-color);
}
.Sidebar-Welcome-Item-Info > p {
  color: var(--font-color-alt);
  overflow: hidden;
  max-width: 100%;
  font-size: 1.2rem;
  margin: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Sidebar-Welcome-Item-Link-Container {
  margin-right: 5px;
}
.Sidebar-Welcome-Item-Link {
  color: var(--font-color);
  border: 2px solid var(--border-color);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sidebar-Welcome-Item-Link > svg {
  font-size: 4rem;
}

@media screen and (max-width: 1020px) {
  .Sidebar-Container {
    display: none;
  }
}
