.Emoji-Editor-Container {
  color: var(--font-color);
  margin-top: 30px;
  text-align: left;
}
@media screen and (max-width: 950px) {
  .Emoji-Editor-Container {
    margin: 0;
  }
  .Emoji-Editor-Container > h1 {
    display: none;
  }
}
.Emoji-Editor-Container > h2 {
  color: var(--font-color-alt);
  margin-bottom: 2px;
}
.Emoji-Editor-Subheader {
  color: var(--font-color-alt);
  margin-bottom: 2px;
  font-size: 1.3rem;
  font-weight: 600;
  max-width: 600px;
}
.Emoji-Editor-Subheader-Margin-Top {
  margin-top: 20px;
}

.Emoji-Editor-Requirements-Header {
  color: var(--font-color-alt);
  margin-bottom: 2px;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 20px;
}
.Emoji-Editor-Requirements-List {
  color: var(--font-color-alt);
  margin-bottom: 2px;
  font-size: 1.3rem;
  font-weight: 600;
  max-width: 600px;
}
.Emoji-Editor-Flex-Container {
  display: flex;
  flex-direction: column;
}
.Emoji-Editor-Flex-Item {
  display: flex;
  color: var(--font-color-alt);
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 10px;
  align-items: center;
  max-width: 600px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 5px;
}

.Emoji-Editor-Flex-Item-Left {
  width: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}
.Emoji-Editor-Flex-Item-Right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.Emoji-Editor-Delete-Button {
  color: rgb(255, 120, 120);
  width: 20px;
  aspect-ratio: 1/1;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 4px;
  box-shadow: var(--shadow-inset);
}
.Emoji-Editor-Delete-Button:hover {
  background-color: var(--background-secondary);
}
.Emoji-Editor-Flex-Item-Image {
  height: 2.8rem;
  aspect-ratio: 1/1;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -35%;
}
.Emoji-Editor-Flex-Item-ID {
  font-size: 1.6rem;
  font-weight: 600;
}
.Emoji-Editor-Unique-Id {
  display: flex;
  align-items: center;
}
.Emoji-Editor-Input {
  height: 28px;
  background-color: var(--background-secondary);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 9px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: var(--shadow-inset);
}
.Emoji-Editor-Input:focus {
  outline: 1px solid var(--primary-accent);
}
.Emoji-Editor-Upload-Button {
  margin-top: 10px;
}
.Emoji-Editor-Usedname {
  margin-left: 5px;
  font-size: 1.2rem;
  color: rgb(255, 120, 120);
  font-weight: 600;
}
