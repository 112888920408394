.Gallery-Showcase-Container {
  width: 100%;
}
.Gallery-Showcase-Container-Inner {
  width: 100%;
}
.Gallery-Showcase-Item-Image {
  width: 100%;
  border-radius: 0px;
  cursor: pointer;
  box-shadow: var(--shadow);
}
.Gallery-Showcase-Item-Image:hover {
  filter: brightness(50%);
}
