.Slider-Toggle {
  box-sizing: border-box;
  border-radius: 0;
  border: solid 3px;
  background-color: black;
  box-shadow: var(--shadow-inset);
  color: var(--border-color);
  display: block;
  height: 30px;
  position: relative;
  width: 60px;
  transition: color 0.3s;
  cursor: pointer;
}
.Slider-Toggle.Slider-Toggle-Active {
  color: var(--primary-accent);
}

.Slider-Toggle-Inner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 18px;
  width: 18px;
  background: linear-gradient(180deg, black, #313130);
  box-shadow: var(--shadow-inset);
  top: 3px;
  left: 3px;
  border: solid 3px;
  border-radius: 0;
  transition: left 0.3s;
}
.Slider-Toggle-Active > .Slider-Toggle-Inner {
  left: 32px;
}
