.EmojiPickerReact .epr-preview {
  padding: 0 var(--epr-horizontal-padding);
  z-index: 1;
  align-items: center;
  border-top: 1px solid var(--epr-preview-border-color);
  height: var(--epr-preview-height);
  position: relative;
  z-index: var(--epr-preview-z-index);
  margin-right: 50px;
}

.EmojiPickerReact .epr-preview .epr-preview-emoji-label {
  /* text-transform: capitalize; */
  color: var(--epr-preview-text-color);
  font-size: var(--epr-preview-text-size);
  padding: var(--epr-preview-text-padding);
}
