.Gif-Modal-Container {
  position: fixed;
  width: 500px;
  height: 50vh;
  min-height: 500px;
  max-height: 500px;
  border-radius: 0px;
  pointer-events: auto;
  background: rgb(24, 24, 27);
  box-shadow: var(--shadow);
  border: 1px solid black;
  z-index: 2;
  box-sizing: border-box;
  padding-bottom: 5px;
}

.GifPickerReact {
  border-radius: 0px !important;
  background: rgb(24, 24, 27) !important;
  border: none !important;
}
.gpr-header {
  border-bottom: 1px solid black !important;
  box-shadow: 0 1px 2px rgb(0 0 0 / 90%), 0 0px 0px rgb(0 0 0 / 90%);
}
.gpr-title-container {
  width: calc(100% - 50px);
}
.gpr-title {
  border-radius: 0px !important;
  background-color: var(--background-secondary) !important;
  box-shadow: var(--shadow-inset);
  font-size: 16px;
}
.gpr-search {
  border-radius: 0px !important;
  width: 100%;
  background: rgba(0, 0, 0, 0.05) !important;
  border: 1px solid transparent !important;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  transition: all 0.15s ease-in-out !important;
  box-shadow: var(--shadow-inset);
  border-radius: 0px !important;
}
.gpr-search:focus {
  border-color: var(--primary-accent) !important;
  border: 1px solid var(--primary-accent) !important;
}
.gpr-btn {
  border-radius: 0px !important;
}
.gpr-btn > img {
  border-radius: 0px !important;
}
.gpr-btn:hover {
  outline-color: var(--primary-accent) !important;
  border-color: var(--primary-accent) !important;
  box-shadow: 0 0 0 2px var(--primary-accent) !important;
}
.gpr-category-placeholder {
  border-radius: 0px !important;
  background: var(--background-secondary) !important;
  box-shadow: var(--shadow-inset);
}
.gpr-result-placeholder {
  border-radius: 0px !important;
  background: var(--background-secondary) !important;
  box-shadow: var(--shadow-inset);
}
.gpr-placeholder {
  border-radius: 0px !important;
  background-color: var(--background-secondary) !important;
  box-shadow: var(--shadow-inset);
}
.Exit-Gif-Modal {
  position: absolute;
  right: 15px;
  top: 18px;
  z-index: 7;
  color: var(--font-color-alt);
  cursor: pointer;
}
.Exit-Gif-Modal > svg {
  font-size: 3rem;
}
.gpr-search-container {
  width: calc(100% - 50px);
}
@media screen and (max-width: 650px) {
  .Gif-Modal-Container {
    position: fixed;
    right: 0;
    max-height: unset;
    height: unset;
    width: unset;
    bottom: 0;
  }
}
