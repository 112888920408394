.Create-Post-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: var(--modal-background-film);
  backdrop-filter: blur(6px);
  z-index: 2;
}

.Create-Post-Modal-Container {
  max-width: 740px;
  width: 100%;
  box-sizing: border-box;

  padding: 0px 10px;
  padding-top: 10px;
  position: absolute;
  cursor: pointer;
  border-left: 2px solid var(--border-color);
  box-shadow: var(--shadow-inset);
  background: var(--background-primary);
  margin: auto;
  max-height: calc(100vh - 60px);
  overflow: scroll;
  top: 10px;
  left: calc(50% - 370px);
}
@media screen and (max-width: 740px) {
  .Create-Post-Modal-Container {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: none;
    max-height: unset;
    border-bottom: 4px solid white;
  }
  .Create-Post-Modal-Container-BG {
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 11;
  }
}
.Create-Post-Modal-Container > .Create-Post-Container {
  padding-top: 5px;
}

.Reply-Post-Container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 0px 12px;
  padding-top: 12px;
  min-height: 100px;
}
.Reply-Post-Left {
  display: flex;
  flex-direction: column;
}
.Reply-Line-Indicator {
  width: 3px;
  background: var(--border-color);
  height: calc(100%);
  align-self: center;
}
.Modal-Reply-Indicator {
  margin: 0;
  margin-top: 20px;
}
.Create-Post-Modal-Mobile-Close {
  display: none;
  position: absolute;
  top: 10px;
  left: 20px;
  color: var(--font-color-alt);
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}
.Create-Post-Modal-Mobile-Close > svg {
  font-size: 3rem;
}
.Create-Post-Modal-Mobile-Send {
  display: none;
  position: absolute;
  top: 10px;
  right: 20px;
  color: var(--primary-accent);
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}
.Create-Post-Modal-Mobile-Send > svg {
  font-size: 2.7rem;
}
@media screen and (max-width: 740px) {
  .Create-Post-Modal-Container {
    padding-top: 50px;
  }
  .Create-Post-Modal-Mobile-Close {
    display: flex;
  }
  .Create-Post-Modal-Mobile-Send {
    display: flex;
  }
}
