.Hashtag-Stats {
  color: var(--font-color);
  display: flex;
  justify-content: space-evenly;
  font-size: 1.3rem;
  font-weight: 600;
  text-shadow: var(--text-shadow);
}
.Hashtag-Stats > div > h3 {
  color: var(--font-color-alt);
}
.Hashtag-Follow-Button {
  border: none;
  /* background: var(--background-secondary); */
  border-top: 1px solid black;
  background: none;
  width: 100%;
  margin-top: 10px;
  text-transform: uppercase;
}
