.Seiso-Input-Placeholder {
  font-size: 15px;
  color: var(--font-color-alt);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 8px;
  left: 12px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
  font-weight: 900;
  text-shadow: var(--text-shadow);
}
