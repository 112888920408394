.App-BG-Container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
}
.App-BG-Film {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.App-BG-Container > video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  touch-action: none;
}
.App-BG-Container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  touch-action: none;
}
@media screen and (max-width: 650px) {
  .App-BG-Container {
    display: none;
  }
}
.Profile-Background-Image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.Profile-Background-Video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
