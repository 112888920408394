.Search-Container {
  min-height: calc(100vh - 50px);
  width: 100%;
  background: rgba(15, 15, 15, 0.6);
  backdrop-filter: blur(6px);
}
.Search-Input-Container {
  background: var(--background-secondary);
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 100px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}
.Search-Output-Background {
  background: var(--background-primary);
  max-width: 750px;
  width: 100%;
}
@media screen and (max-width: 650px) {
  .Search-Input-Container {
    padding: 10px 10px;
  }
}
.Search-Input {
  max-width: 800px;
  width: 100%;
  height: 44px;
  background: none;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--border-color);
  font-size: 2.5rem;
  color: var(--font-color);
  transition: all 0.5s;
  border-radius: 0;
}
.Search-Input:focus {
  border-bottom: 2px solid var(--primary-accent);
}
.Search-Input[type='search']::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
}

.Search-Output-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Search-Output-Users {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}
.Search-Output-Users-Header {
  background: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid var(--primary-accent);
  padding: 12px 12px;
  display: flex;
  max-width: 750px;
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  box-sizing: border-box;
}
.Search-Output-User-Item {
  width: 100%;
  max-width: 750px;
}

.Search-Output-Posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.Search-Output-Posts > .Post-Container {
  padding: 0;
}
.Search-Output-Posts > .Post-Container > .Post-Container-Inner {
  border: none;
  border-bottom: 2px solid var(--border-color);
}
.Search-Output-Posts > .Post-Container > .Post-Container-Inner:hover {
  background: rgba(0, 0, 0, 0.5);
}
.Search-Output-No-Results {
  font-size: 1.5rem;
  border: 2px dashed var(--border-color);
  max-width: 300px;
  justify-self: center;
  margin-top: 40px;
  padding: 40px;
  box-shadow: 0px 0px 10px black inset;
}
