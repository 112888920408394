.TermsOfService-Container {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  max-width: 1000px;
  width: 100%;
}
.TermsOfService-Text-Container {
  width: 100%;
  min-height: calc(100vh - 50px);
  background: var(--background-primary);
  backdrop-filter: blur(6px);
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: var(--shadow-inset);
  box-sizing: border-box;
}
.TermsOfService-Text-Container {
  color: var(--font-color);
  font-weight: 600;
}
.TermsOfService-Text-Container > p {
  color: var(--font-color-alt);
  font-size: 1.2rem;
}
.TermsOfService-Text-Container > ol {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
.TermsOfService-Text-Container > ol > li {
  margin: 0;
  font-size: 1.5rem;
}
.TermsOfService-Text-Container > ol > li > h3 {
  font-size: 1.4rem;
}
.TermsOfService-Text-Container > ol > li > p {
  font-size: 1.2rem;
  color: var(--font-color-alt);
}
.TermsOfService-Text-Container > ol > li > ul {
  padding: 0;
  margin-left: 30px;
}
.TermsOfService-Text-Container > ol > li > ul > li {
  list-style: square;
  font-size: 1.2rem;
  color: var(--font-color-alt);
}
