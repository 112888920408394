.Socials-Block-Container * {
  text-align: left;
}
.Socials-Block-Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  background: var(--background-secondary);
  box-shadow: var(--shadow-inset);
  padding: 1rem 1rem 1rem 1rem;
}
.Upload-Socials-Info {
  text-align: left;
}
.Upload-Socials-Info > h1 {
  color: var(--font-color);
  margin-bottom: 0;
}
.Upload-Socials-Info > h2 {
  color: var(--font-color-alt);
}
.Socials-Block-Item {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: var(--shadow-inset);
}
@media (hover: hover) {
  .Socials-Block-Item:hover {
    background: var(--background-primary);
    cursor: pointer;
  }
}
.Socials-Block-Item-Alt {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: var(--shadow-inset);
}
.Socials-Block-Item-Img {
  height: 100px;
  width: 100px;
}
.Socials-Block-Item-Add {
  height: 100px;
  width: 100px;
  display: flex;
}
.Socials-Block-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Socials-Block-Item-Input {
  border-radius: 3px;
  margin-left: 10px;
  width: calc(100% - 150px);
  background: var(--background-secondary);
}
.Socials-Block-Item-Info {
  margin-left: 10px;
}
.Socials-Block-Item-Info > h2 {
  color: var(--font-color);
}
.Socials-Block-Item-Info > p {
  font-size: 1.2rem;
  color: var(--font-color-alt);
}
.Socials-Block-Item-Input-Container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 70%;
}
.Socials-Block-Item-Input-Container > input {
  width: 95%;
  height: 40px;
  background: var(--background-secondary);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  margin: 5px;
  box-sizing: border-box;
  box-shadow: var(--shadow-inset);
}

.Socials-Block-Item-Input-Container > input:focus {
  outline: 1px solid var(--primary-accent);
}
.Socials-Block-Item-Options {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  height: 100px;
}
.Socials-Block-Item-Option {
  color: var(--font-color-alt);
  margin: auto;
  padding: 2px;
}
.Socials-Block-Item-Option > svg {
  font-size: 2rem;
}

@media (hover: hover) {
  .Socials-Block-Item-Option:hover {
    color: var(--font-color);
    cursor: pointer;
  }
}

@media screen and (max-width: 950px) {
  .Socials-Block-Container {
    display: flex;
    flex-direction: column;
  }

  .Socials-Block-Item-Input-Container > input {
    background: var(--background-primary);
  }
}
@media screen and (max-width: 530px) {
  .Socials-Block-Item-Img {
    height: 50px;
    width: 50px;
  }
  .Socials-Block-Item-Add {
    height: 50px;
    width: 50px;
    display: flex;
  }
  .Socials-Block-Item-Add > svg {
    font-size: 4rem;
  }
}
