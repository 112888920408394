.Browse-Container {
  min-height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Browse-Container-Inner {
  width: 100%;
  max-width: 900px;
  background: var(--background-primary);
  min-height: calc(100vh - 50px);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.Browse-Preview-Header {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-accent);
  font-family: 'fujimaru';
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}
.Browse-Preview-Header > span {
  filter: brightness(75%);
}
.Browse-Live-Profiles-Preview {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.Browse-Live-Profiles-Preview-Item {
  aspect-ratio: 16/9;
  width: 400px;
  margin: 15px;
  justify-items: center;
  position: relative;
  box-shadow: var(--shadow-inset);
  cursor: pointer;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column-reverse;
  outline: 1px solid var(--border-color);

  transition-property: filter, transform, background-size, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  overflow: hidden;
}
.Browse-Live-Profiles-Preview-Item:hover {
  outline: 1px solid var(--primary-accent);
  transform: scale(1.02);
}
.Browse-Live-Profiles-Preview-Item:after {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -1;
}
.Browse-Live-Profiles-Preview-Item:hover > .Browse-Live-Profiles-Preview-Item-Status {
  height: calc(100% - 125px);
  opacity: 1;
  transform: translateY(0px);
}
.Browse-Live-Profiles-Preview-Item:hover:after {
  opacity: 1;
}
.Browse-Live-Profiles-Preview-Item-Fade {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(rgba(15, 15, 15, 0) 60%, rgba(15, 15, 15, 0.733) 70%, rgb(15, 15, 15) 90%);
}
.Browse-Live-Profiles-Preview-Item-Img {
  width: 100%;
}
.Browse-Live-Profiles-Preview-Item-Info {
  display: flex;
  text-align: left;
  margin-top: 5px;
}
.Browse-Live-Profiles-Preview-Item-Info-R {
  margin-left: 5px;
}
.Browse-Live-Profiles-Preview-Item-Info-Displayname {
  margin: 0px 10px 8px 20px;
  text-shadow: 0px 0px 10px #000;
  font-weight: bold;
  text-transform: none;
  letter-spacing: 0px;
  font-size: 2.4rem;
  line-height: 115%;
  color: #fff;
  z-index: 0;
  text-align: left;
  display: flex;
  align-items: center;
  text-shadow: 1px 1px 1px black;
}
.Browse-Live-Profiles-Preview-Item-Info-Displayname > span {
  margin-left: 5px;
}
.Browse-Live-Profiles-Preview-Item-Info-Displayname > span * {
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Browse-Live-Profiles-Preview-Item-Info-Displayname-Emoji {
  height: 1.2em;
  width: 1.2em;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -20%;
}
.Browse-Live-Profiles-Preview-Item-Stream-Status {
  color: var(--font-color);
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 20px;
  margin-bottom: 8px;
  z-index: 0;
  text-align: left;
  font-weight: 900;
  text-shadow: 1px 1px 1px #000;
}

.Browse-Live-Profiles-Preview-Item-Stream-Status-Twitch {
  color: var(--twitch-purple);
}
.Browse-Live-Profiles-Preview-Item-Stream-Status-Youtube {
  color: var(--youtube-red);
}
.Browse-Live-Profiles-Preview-Item-Status {
  font-size: 1.8rem;
  color: var(--font-color-alt);
  z-index: 2;
  margin-left: 20px;
  margin-bottom: 0px;
  opacity: 0;
  transform: translateY(20px);
  height: 0px;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-property: height, opacity, transform;
  text-align: left;
  text-shadow: 2px 2px 2px #000;
}

.Browse-Live-Profiles-Preview-Item-Status * p {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.Browse-Popular-Hashtags {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.Browse-Popular-Hashtags-Item {
  flex: 1 290px;
  padding: 10px;
  box-shadow: var(--shadow-inset);
  margin: 5px;
  text-align: left;
  cursor: pointer;
}
.Browse-Popular-Hashtags-Item:hover {
  background: var(--background-secondary);
}
.Browse-Popular-Hashtags-Item-Top {
  font-size: 2rem;
  color: var(--primary-accent);
}
.Browse-Popular-Hashtags-Item-Bottom {
  font-size: 1.5rem;
  color: var(--font-color-alt);
}
.Trending-Posts-Preview {
  display: flex;
  flex-direction: column;
}
.Trending-Posts-Preview > .Post-Container {
  width: unset;
  max-width: unset;
}
